









































































































































































































import { Component, Vue } from "vue-property-decorator";
import AreaSelect from "@/components/area-select.vue";
import GoodsSelect from "@/components/goods-select/index.vue";
import {
    apiCouponAdd,
    apiCouponDetail,
    apiCouponEdit,
} from "@/api/marketing/coupon";
@Component({
    components: {
        AreaSelect,
        GoodsSelect,
    },
})
export default class AddSupplier extends Vue {
    /** S Data **/

    id!: any; //当前的ID

    disabled: any = false; //是否禁用

    status: any = 0; //状态

    couponList = {
        name: "", //优惠券名称
        money: "", //必选	float	优惠券面额
        condition_type: 1, //必选	int	使用条件 1=无门槛，2=订单满多少钱
        condition_money: "", //否	float	使用条件金额，当condition_type=2时必填
        send_total_type: 1, //必选	int	发放数量类型 1=不限数量，2=固定数量
        send_total: "", //否	int	发放数量，send_total_type=2时必填
        use_time_type: 1, //必选	string	用券时间类型：1=固定时间；2=领券当天起；3=领券次日起
        use_time_start: "", //否	datetime	用券开始时间：use_time_type=1时生效
        use_time_end: "", //否	datetime	用券结束时间：use_time_type=1时生效
        use_time: "", //否	int	多少天内可用：use_time_type=2/3时生效
        use_time1: "",
        get_type: 1, //必选	int	领取类型：1=用户领取, 2=商家赠送
        get_num_type: 1, //必选	int	领取次数类型：1=不限制领取次数；2=限制次数；3=每天限制数量
        get_num: "", //否	int	领取次数类型: get_type=2/3时生效
        get_num1: "",
        use_goods_type: 1, //必选	int	适用商品类型：1=全部商品；2=指定商品；3=指定商品不可用
        use_goods_ids: [], //使用商品ID, use_goods_type=2 / 3 时必填, 且数组不可为空
    };

    goodsSelectData: any = [];

    rules = {
        //表单验证
        name: [
            {
                required: true,
                message: "请输入优惠券名称",
                trigger: ["blur", "change"],
            },
        ],
        money: [
            {
                required: true,
                message: "请输入优惠券面额",
                trigger: ["blur", "change"],
            },
        ],
        send_total: [
            {
                required: true,
                message: "请输入发放数量",
                trigger: ["blur", "change"],
            },
        ],
    };

    /** E Data **/

    /** S Method **/

    // 初始化日期格式
    add(m: number) {
        return m < 10 ? "0" + m : m;
    }
    baseTime(event: any) {
        const d = new Date(event);
        return `${this.add(d.getFullYear())}-${this.add(
            d.getMonth() + 1
        )}-${this.add(d.getDate())} ${this.add(d.getHours())}:${this.add(
            d.getMinutes()
        )}:${this.add(d.getSeconds())}`;
    }
    // 开始时间
    startTime(event: String) {
        this.couponList.use_time_start = this.baseTime(event);
    }
    // 结束时间
    endTime(event: any) {
        this.couponList.use_time_end = this.baseTime(event);
    }

    // 获取到选择商品的数据
    goodsData(event: any) {
        if (event.length <= 0) return;
        this.goodsSelectData = event;
    }

    // 移除选中的商品
    removeSelectGoods(id: number) {
        console.log(id);
        for (let i = 0; i < this.goodsSelectData.length; i++) {
            if (this.goodsSelectData[i].id == id) {
                this.goodsSelectData.splice(i, 1);
            }
        }
    }

    submit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement;
        refs.validate((valid: boolean): any => {
            if (!valid) return;

            if (this.couponList.get_num_type == 3) {
                this.couponList.get_num = this.couponList.get_num1;
            }
            if (this.couponList.use_time_type == 3) {
                this.couponList.use_time = this.couponList.use_time1;
            }
            if ((this, this.couponList.use_goods_type !== 1)) {
                this.couponList.use_goods_ids = this.goodsSelectData.map(
                    (item: any) => item.id
                );
            } else {
                this.couponList.use_goods_ids = [];
            }
            // // 新增还是删除
            if (this.id) {
                this.couponEdit();
            } else {
                this.couponAdd();
            }
        });
    }

    // 编辑优惠券
    couponEdit() {
        apiCouponEdit({ ...this.couponList })
            .then((res) => {
                this.$message.success("修改成功!");
                setTimeout(() => this.$router.go(-1), 500);
            })
            .catch(() => {
                this.$message.error("修改失败!");
            });
    }

    // 添加优惠券
    couponAdd() {
        apiCouponAdd({ ...this.couponList })
            .then((res) => {
                this.$message.success("修改成功!");
                setTimeout(() => this.$router.go(-1), 500);
            })
            .catch(() => {
                this.$message.error("添加失败!");
            });
    }

    // 获取优惠券详情
    getCouponDetail() {
        apiCouponDetail(this.id).then((res: any) => {
            if (res.get_num_type == 3) {
                res.get_num1 = res.get_num;
                res.get_num = "";
            }
            if (res.use_time_type == 3) {
                res.use_time1 = res.use_time;
                res.use_time = "";
            }
            if (res.goods) {
                this.goodsSelectData = res.goods;
            }
            this.couponList = res;
        });
    }

    /** E Method **/

    created() {
        this.status = this.$route.query.status;
        this.disabled = this.$route.query.disabled == "true" ? true : false;
        this.id = this.$route.query.id;
        this.id && this.getCouponDetail();
    }
}
